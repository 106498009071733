import React, { Component } from "react"
import Banner from '../../images/top-banner.png'

export class HeaderBannerBlogPg extends Component {
    render() {
        return (
            <section className="banner-header banner-img-top valign bg-img bg-fixed" style={{ backgroundImage: `url(${Banner})` }}>
                <div className="container mx-auto">
                    <div className="flex flex-wrap mt-[6rem] -mb-10 lg:mt-26 px-4">
                        <div className="md:w-3/4">
                            <h6>Our Blog</h6>
                            <h1>Latest <span>News</span></h1>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}