import React from 'react'
import { Link } from "gatsby"

export default function Breadcrumb({ links }) {
    return (
        <ol className="flex items-center overflow-ellipsis space-x-1 truncate pt-1">
            {links.map((link, l) => (
                <li key={link.label}>
                    <div className="flex items-center">
                        {l > 0 ? <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                        </svg> : null}
                        <Link to={link.to} title={link.label} alt={link.label} aria-current={links.length - 1 === l ? 'page' : null} className={`text-xs md:text-sm font-normal text-gray-500 hover:text-lightblue transition duration-150 ease-in-out ${l > 0 ? 'ml-2' : ''}`}>{link.label}</Link>
                    </div>
                </li>
            ))}
        </ol>
    )
}