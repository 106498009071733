import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HeaderBannerBlogPg } from "../components/Blog-Page/header-banner-blog"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Breadcrumb from '../components/breadcrumb'

class BlogPage extends React.Component {
    render() {
        const posts = this.props.data.allContentfulBlog.edges
        return (
            <Layout>
                <Seo
                    title="Home Improvement Blog: Expert Insights on Kitchen Remodeling, Bathroom Remodeling, Siding, Windows & Doors, Decks & Patios, and Interior Design by Kaylar Construction"
                    description='Uncover a treasure trove of inspiration! Our blog is a treasure chest of knowledge, featuring captivating articles on kitchen/bathroom remodeling, siding, windows & doors, decks & patios, and interior design. Explore and ignite your imagination today!'
                    keywords={[
                        'Kaylar Construction Blog',
                        'Kitchen remodeling',
                        'Bathroom remodeling',
                        'Siding',
                        'Siding',
                        'Windows and doors',
                        'Decks and patios',
                        'Interior design services',
                    ]}
                    location="/blog"
                    isBlogPage={true}
                    type="BlogPosting"
                />
                <div>
                    <HeaderBannerBlogPg />
                    <div>
                        <div className="bg-white border border-gray-200 shadow-lg">
                            <nav className="flex container mx-auto px-4 pt-1 pb-3" aria-label="Breadcrumb">
                                <Breadcrumb links={[
                                    { to: '/', label: 'Home' },
                                    { to: '/blog', label: 'Blog' },
                                ]} />
                            </nav>
                        </div>
                        <div className="container mx-auto py-7 lg:py-8 px-4 pb-9 lg:pb-12">
                            <div className="mx-auto grid gap-x-8 lg:grid-cols-2 xl:grid-cols-3 gap-y-8">
                                {posts && posts.map(({ node: post }) => {
                                    const title = post.title
                                    const image = getImage(post.image)
                                    let blogCardTitle = post.title
                                    return (
                                        <div className="flex flex-col mx-auto border rounded-lg shadow-xl mb-3 xl:mb-12 bg-white" key={post.slug}>
                                            <article>
                                                <div className="max-w-md mx-auto hover:opacity-80 rounded-lg lg:h-[36.5rem] xl:h-[36rem]">
                                                    <Link to={post.slug} title={blogCardTitle} alt={blogCardTitle}>
                                                        <GatsbyImage className="h-56 rounded-t-lg z-0" src="" image={image} title={blogCardTitle} alt={blogCardTitle} />
                                                    </Link>
                                                    <div className="px-5 py-5">
                                                        <p className="font-medium text-gray-600 uppercase text-sm">{post.category}</p>
                                                        <Link to={post.slug} title={blogCardTitle} alt={blogCardTitle}>
                                                            <h3 className="pt-3 font-bold text-lg lg:h-[4rem]">{title}</h3>
                                                            <p className="py-3 text-base lg:h-[6rem]">{post.description}</p>
                                                        </Link>
                                                        <p>{post.author}</p>
                                                        <p className="pb-2 pt-1">{post.date}</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default BlogPage
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulBlog(sort: { fields: date, order: DESC }) {
        edges{
            node{
                title
                category
                slug
                author
                body {
                    body
                }
                description
                date(formatString: "MMMM Do, YYYY")
                image {
                   gatsbyImageData(
                       width: 600
                       placeholder: NONE
                       formats: [AUTO, WEBP, AVIF]
                   )
                   title
                   description
                }
            }
        }
    }
}
`